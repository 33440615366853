import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';

export const styles = css`
    .ZakazBanner__logo {
      width: 145px;
    }

    @media ${phone} {
      .ZakazBanner__logo {
        margin-bottom: 30px;
      }
    }
  `;
