import cn from 'classnames';
import { ServicePage } from '../../../redux/apiTypes';
import { styles } from './FooterMenu.styles';

interface Props {
  host: string;
  menuTitle?: string;
  items: ServicePage[];
  forMobileMenu?: boolean;
}

export function getLinkAttributes(page: ServicePage, host: string) {
  const isExternal = !page.web_url.includes(host);
  const href = isExternal
    ? page.web_url
    : page.web_url.split(host)[1];

  return {
    href,
    rel: isExternal ? 'nofollow noreferrer' : undefined,
    target: isExternal ? '_blank' : undefined,
  };
}

export const FooterMenu = (props: Props) => {
  const { items, menuTitle, host, forMobileMenu } = props;


  return (
    <nav className={cn('FooterMenu', { FooterMenu_mobileMenu: forMobileMenu })}>
      {menuTitle && (
        <h2 className='FooterMenu__title'>{menuTitle}</h2>
      )}

      <ul className='FooterMenu__list'>
        {items.map((page) => {
          const attrs = getLinkAttributes(page, host);

          return (
            <li key={page.id} className='FooterMenu__item'>
              <a
                className='FooterMenu__link'
                href={attrs.href}
                rel={attrs.rel}
                target={attrs.target}
              >
                {page.title}
              </a>
            </li>
          );
        })}
      </ul>

      <style jsx>{styles}</style>
    </nav>
  );
};

export default FooterMenu;
