import { useAppConfig } from 'src/utils/appConfig/useAppConfig';
import { styles } from './ZakazBanner.styles';
import ZakazLogo from './ZakazLogo';

interface Props {
  locale: string;
}

const ZakazBanner = (props: Props) => {
  const { locale } = props;

  const { country, imgRoot } = useAppConfig();

  const logo = <ZakazLogo country={country} imgRoot={imgRoot} />;

  return (
    <div className='ZakazBanner'>
      {country === 'ua'
        ? (
          <a
            href={`https://zakaz.ua/${locale}/`}
            data-testid='zakazBannerLink'
            data-marker='Zakaz Banner'
            rel='nofollow'
          >
            {logo}
          </a>
        )
        : logo
      }
      <style jsx>{styles}</style>
    </div>
  );
};

export default ZakazBanner;
