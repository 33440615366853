import css from 'styled-jsx/css';
import typography from '../../../styles/typography';

export const styles = css`
    .FooterMenu__list {
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .FooterMenu__item {
      margin-bottom: 11px;
    }

    .FooterMenu__item:last-child {
      margin-bottom: 0;
    }

    .FooterMenu__title {
      ${typography.h3}
      color: #fff;
      margin-bottom: 11px;
    }

    .FooterMenu__link {
      font-size: 14px;
      text-decoration: none;
      color: var(--colors-white);
      opacity: 0.6;
    }

    .FooterMenu__link:hover {
      opacity: 1;
    }

    .FooterMenu_mobileMenu .FooterMenu__link {
      opacity: 1;
      color: var(--colors-text)
    }

    .FooterMenu_mobileMenu .FooterMenu__item {
      padding: 10px;
      margin: 0;
    }
  `;
